import React from 'react';
import ReactDOM from 'react-dom';

import NextApp from './NextApp';
// import registerServiceWorker from './registerServiceWorker';
// Add this import:

ReactDOM.render(
  // Wrap App inside AppContainer
  <NextApp />,
  document.getElementById('root')
);
