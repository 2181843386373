import { useEffect } from 'react';
import { Layout } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getCookies } from 'fetch/tokenHandler';
import { useGetUserInfo } from 'useSWR';
import { CircularProgress, InfoView } from 'components';

import App from 'routes/index';
import HorizontalDark from '../Topbar/HorizontalDark/index';
import Sidebar from '../Sidebar/index';

const { Content, Footer } = Layout;

const MainApp = () => {
  const location = useLocation();
  const history = useHistory();
  const { token } = getCookies();
  const { isLoading, isResourceUser } = useGetUserInfo();

  useEffect(() => {
    if (isLoading) return;

    if (!token || !isResourceUser) {
      const { pathname } = location;

      sessionStorage.setItem('returnURL', pathname);
      history.push('/giris');
    } else {
      const returnURL = sessionStorage.getItem('returnURL');

      if (returnURL) {
        sessionStorage.removeItem('returnURL');
        history.push(returnURL);
      }
    }
  }, [history, isLoading, isResourceUser, location, token]);

  if (isLoading) return <CircularProgress />;

  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        <HorizontalDark />

        <Content className="gx-layout-content gx-container-wrap padding-fixed-header">
          <App />

          <InfoView />

          <Footer>
            <div className="gx-layout-footer-content">
              <span className="gx-d-none gx-d-sm-inline-block">
                <FormattedMessage id="allRightsReserved" />
                &nbsp;
              </span>

              <FormattedMessage className="asd" id="app.footer" values={{ year: new Date().getFullYear() }} />
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainApp;
