import { SWITCH_LANGUAGE } from 'constants/ActionTypes';
import {
  LAYOUT_TYPE,
  NAV_STYLE,
  THEME_COLOR,
  THEME_TYPE,
  LAYOUT_TYPE_FULL,
  THEME_TYPE_DARK,
  NAV_STYLE_DARK_HORIZONTAL,
} from '../../constants/ThemeSetting';

const initialSettings = {
  navStyle: NAV_STYLE_DARK_HORIZONTAL,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_DARK,

  isDirectionRTL: false,
  locale: {
    languageId: 'turkish',
    locale: 'tr',
    name: 'Türkçe',
    icon: 'tr',
  },
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType,
      };
    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor,
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle,
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType,
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
};

export default settings;
