import axios from './customAxios';
import { API_URL } from './Config';

export const easyPost = async (path, data) =>
  await axios
    .post(`${API_URL}${path}`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .catch((err) => {
      throw err;
    });

export const easyPostToken = async (path, data) => {
  var config = {
    method: 'post',
    url: `${API_URL}${path}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
  const response = await axios(config);
  return response;
};
