import { useMemo } from 'react';
import { Divider, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UserProfile from 'containers/Sidebar/UserProfile';
import menuItem from '../menuItems';
import { useGetUserInfo } from 'useSWR';

const SidebarContent = () => {
  const { pathname } = useSelector(({ common }) => common);
  const { isAdmin, canSeeManual } = useGetUserInfo();
  // const getNoHeaderClass = (navStyle) => {
  //   if (
  //     navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
  //     navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
  //   ) {
  //     return 'gx-no-header-notifications';
  //   }
  //   return '';
  // };

  // const selectedKeys = pathname.substr(1);
  // const defaultOpenKeys = selectedKeys.split('/')[1];
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const menu = useMemo(
    () =>
      menuItem.filter((item) => {
        if (isAdmin) {
          return item;
        }
        return item.situation.includes('all') || (canSeeManual && item.situation.includes('canSeeManual'));
      }),
    [isAdmin, canSeeManual]
  );

  return (
    <>
      <div style={{ marginLeft: -16 }} className="gx-my-4 gx-d-flex gx-justify-content-center">
        <UserProfile pageType="side" />
      </div>
      <Divider style={{ color: 'white', backgroundColor: '#ffffff' }} />
      <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} mode="inline" className="gx-text-white" theme="dark">
        {menu.map((item) => (
          <Menu.Item icon={item.icon} key={item.key}>
            <Link to={item.url} style={{ color: 'inherit' }}>
              <FormattedMessage id={item.textId} />
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
