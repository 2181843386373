import React, { memo, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useGetUserInfo } from 'useSWR';
import { CircularProgress, JumpTop } from 'components';
import NotFound from 'routes/404';
import ErrorPage from 'routes/500';

import { allRoutes } from './allRoutes';

const App = memo(() => {
  const { isAdmin, isLoading, isE2User, isResourceAndGuideUser } = useGetUserInfo();

  const routes = useMemo(() => {
    if (isAdmin) return allRoutes;

    const filtered = allRoutes.filter((route) => {
      const { situation } = route;

      if (situation === 'all') return true;
      if (situation === 'onlyE2') return isE2User;
      if (situation === 'onlyGuides') return isResourceAndGuideUser;

      return false;
    });

    return filtered;
  }, [isAdmin, isE2User, isResourceAndGuideUser]);

  if (isLoading) return <CircularProgress />;

  return (
    <div className="gx-main-content-wrapper gx-px-0 gx-px-sm-2">
      <JumpTop />

      <Switch>
        {routes?.map((route) => (
          <Route key={route.key} exact path={route.path} component={route.component} />
        ))}
        <Route path="/hata">
          <ErrorPage />
        </Route>
        <Route path="/404">
          <NotFound />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
});

export default App;
