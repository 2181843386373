import React from 'react';
import { CopyOutlined, InfoCircleFilled, NodeIndexOutlined, SnippetsOutlined } from '@ant-design/icons';

export default [
  {
    key: 'e-kaynaklar',
    url: '/e-kaynaklar',
    textId: 'resources.Title',
    icon: <CopyOutlined />,
    situation: 'all',
  },
  {
    key: 'iys-kullanim-kilavuzu',
    url: '/iys-kullanim-kilavuzu',
    textId: 'navbar.userManual',
    icon: <SnippetsOutlined />,
    situation: 'onlyE2',
  },
  {
    key: 'kilavuzlar-yonergeler',
    url: '/kilavuzlar-yonergeler',
    textId: 'navbar.instruction',
    icon: <NodeIndexOutlined />,
    situation: 'onlyGuides',
  },
  {
    key: 'hakkinda',
    url: '/hakkinda',
    textId: 'navbar.about',
    icon: <InfoCircleFilled />,
    situation: 'all',
  },
];
