import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { InfoView } from 'components';

import { ISAMLogo, ISAMDiaLogo } from './Logos';

import 'styles/customCSS/customFilter.less';

const Login = ({ children }) => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card className="gx-p-2 login-card">
        <div style={{ margin: '24px 48px 24px 48px' }} className="gx-p-2">
          <Link to="/">
            <ISAMDiaLogo />
          </Link>
        </div>

        <h4 className="gx-mx-auto gx-w-75 gx-text-center gx-font-weight-semi-bold gx-p-2  gx-text-grey">
          <FormattedMessage id="app.userAuth.Header" values={{ element: <br /> }} />
        </h4>

        {children}

        <div className="gx-p-2 gx-d-flex gx-align-items-center gx-justify-content-center">
          <ISAMLogo width={102} />
        </div>

        <InfoView />
      </Card>
    </div>
  );
};

export default Login;
