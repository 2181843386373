import useSWR from 'swr';

const useSourcesTypes = () => {
  const { data, error } = useSWR('/resource_types');
  return {
    types: data?.['hydra:member'],
    isLoading: !error && !data,
    isError: error,
  };
};

export default useSourcesTypes;
