import useSWR from 'swr';

const useSources = ({ searchKey = '', filter = '' }) => {
  const params = new URLSearchParams({
    searchKey,
  });

  if (filter) {
    const filters = filter
      .slice(1)
      .split('&')
      .map((filter) => filter.split('='));

    filters.forEach(([filter, value]) => params.append(filter, value));
  }

  const url = `/resources?${params.toString()}`;
  const { data, error } = useSWR(url);

  return {
    sources: data?.['hydra:member'],
    isLoading: !error && !data,
    isError: error,
  };
};

export default useSources;
