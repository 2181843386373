import { easyPost, easyPostToken } from './easyfetch';
import { setCookies, removeCookies } from './tokenHandler';

export const login = async (user) => {
  try {
    const { data } = await easyPostToken('/login', user);
    setCookies(data.token, data.refreshToken);
    return data;
  } catch (err) {
    return err;
  }
};

export const logout = async () => {
  try {
    const { data } = await easyPostToken('/logout ');
    removeCookies();
    return data;
  } catch (err) {
    return err;
  }
};
export const passwordResetRequest = async (email) => {
  try {
    const res = await easyPost('/reset_password/request', email);
    const { data } = res;
    return data;
  } catch (err) {
    return err;
  }
};
export const passwordResetValidateToken = async (token) => {
  try {
    const res = await easyPost('/reset_password/validate_token', token);
    const { data } = res;
    return data;
  } catch (err) {
    return err;
  }
};

export const passwordReset = async (values) => {
  try {
    const res = await easyPost('/reset_password', values);
    const { data } = res;
    return data;
  } catch (err) {
    return err;
  }
};
