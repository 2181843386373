import React from 'react';
import { Helmet } from 'react-helmet';

export const Title = ({ children }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title> {children} - İslam Ansiklopedisi</title>
    </Helmet>
  );
};
