import React, {useState, useEffect} from 'react';
import { Layout } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import HorizontalNav from '../HorizontalNav';
import { toggleCollapsedSideNav } from 'appRedux/actions/Setting';

import UserProfile from 'containers/Sidebar/UserProfile';
import {
  EKaynaklar,
  ISAMDiaLogoWh,
  IsamLogoMobile,
  HamburgerMenu,
} from 'containers/Logos';

import { useMediaQuery } from 'hooks';

const { Header } = Layout;

const HorizontalDark = () => {
  const { isSmScreen } = useMediaQuery();
  const dispatch = useDispatch();
  const { navCollapsed } = useSelector(({ common }) => common);

  const [shrinkHeader, setShrinkHeader] = useState('');

  const handleScroll = () => {
    const position = window.pageYOffset;
    if(position >= 20) {
      setShrinkHeader('shrinked-header');
    }else {
      setShrinkHeader('');
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="gx-header-horizontal gx-header-horizontal-dark sticky-header"
      style={{
        backgroundImage: require('assets/images/header.svg'),
      }}
    >
      <Header className={`gx-header-horizontal-main gx-d-flex gx-d-lg-block ${shrinkHeader}`}>
        <div className="gx-container ">
          <div className="gx-header-horizontal-main-flex gx-justify-content-between">
            <div className="gx-d-block gx-d-sm-none gx-linebar gx-mr-xs-3">
              <HamburgerMenu
                className="gx-icon-btn icon icon-menu hamburger-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div>
            <Link to="/" className="logo isam-logo-wrapper">
              {isSmScreen ? (
                <IsamLogoMobile className="isam-logo-mobile" />
              ) : (
                <ISAMDiaLogoWh className="isam-logo" />
              )}
            </Link>
            <Link to="/">
              <div className="logo ">
                <EKaynaklar className="e-kaynaklar-logo" />
              </div>
            </Link>
          </div>
        </div>
      </Header>
      <div className="gx-header-horizontal-nav gx-d-none gx-d-sm-block">
        <div className="gx-container">
          <div className="gx-header-horizontal-nav-flex ">
            <div className="gx-w-75">
              <HorizontalNav />
            </div>

            <div className="gx-language gx-d-flex gx-ml-auto gx-my-auto ">
              <UserProfile />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HorizontalDark;
