import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { login } from 'fetch/fetchList';
import { getCookies } from 'fetch/tokenHandler';
import { fetchError, fetchStart, fetchSuccess } from 'appRedux/actions';
import { useGetUserInfo } from 'useSWR';
import { showErrorMessage } from 'utils';
import { Title } from 'components';

import Login from './Login';

import '../styles/customCSS/customFilter.less';

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { formatMessage } = useIntl();
  const { isLoading, isResourceUser } = useGetUserInfo();
  const { token } = getCookies();

  const loading = useSelector(({ common }) => common.loading);

  const onFinish = async (values) => {
    dispatch(fetchStart());

    try {
      await login(values);

      const returnURL = sessionStorage.getItem('return');
      sessionStorage.removeItem('return');

      dispatch(fetchSuccess());
      history.push(returnURL || '/e-kaynaklar');
    } catch (error) {
      const id = [401, 404].includes(error) ? 'loginError' : 'loginFetchError';
      dispatch(fetchError(formatMessage({ id: `app.userAuth.${id}` }, { error: `${error}` })));
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const ssoError = params.get('ssoError');

    if (ssoError) {
      params.delete('ssoError');
      history.push({ search: params.toString() });
      return showErrorMessage(formatMessage({ id: 'app.userAuth.ssoLoginError' }, { error: ssoError }));
    }

    if (!token || !isResourceUser) return;

    const returnURL = sessionStorage.getItem('returnURL');

    if (returnURL) {
      sessionStorage.removeItem('returnURL');
      history.push(returnURL);
    } else {
      history.push('/e-kaynaklar');
    }
  }, [formatMessage, history, isResourceUser, search, token]);

  if (token || (isLoading && isResourceUser)) return null;

  return (
    <Login>
      <Title>Giriş</Title>

      <div className="gx-m-4 gx-p-2">
        <Form initialValues={{ remember: true }} name="basic" onFinish={onFinish} className="gx-signin-form gx-form-row0" size="large">
          <Form.Item
            className="gx-mb-3"
            rules={[
              {
                required: true,
                type: 'email',
                message: <FormattedMessage id="app.userAuth.EmailErrorCheck" />,
              },
            ]}
            name="email"
          >
            <Input placeholder={formatMessage({ id: 'app.email' })} data-cy="email" />
          </Form.Item>
          <Form.Item
            className="gx-mb-3"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="app.userAuth.PassError" />,
              },
            ]}
            name="password"
          >
            <Input.Password type="password" placeholder={formatMessage({ id: 'app.password' })} data-cy="password" />
          </Form.Item>

          <Form.Item className="gx-text-right">
            <Button type="primary" className="gx-mb-0  gx-w-100" htmlType="submit" loading={loading} data-cy="loginButton">
              <FormattedMessage id="app.userAuth.signIn" />
            </Button>
          </Form.Item>
          <div className="gx-text-center gx-my-2">
            <FormattedMessage id="app.userAuth.or" />
          </div>

          <div className="gx-d-flex gx-justify-content-center">
            <a style={{ textDecoration: 'none' }} href={`${process.env.REACT_APP_PUBLIC_BASE_URL}/sso/login`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                height="40px"
                viewBox="0 0 3250 522"
                className="SignInButton"
                style={{
                  border: '1px solid #8c8c8c',
                  padding: 'auto 12px',
                }}
              >
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html: ".fnt0 {font-size: 225px;font-family: 'Segoe UI Semibold', 'Segoe UI'; text-decoration: none;}",
                  }}
                />
                <rect className="fil0" x={2} y={2} width={3500} height={517} fill="#fff" />
                <rect x={150} y={129} width={122} height={122} fill="#F35325" />
                <rect x={284} y={129} width={122} height={122} fill="#81BC06" />
                <rect x={150} y={263} width={122} height={122} fill="#05A6F0" />
                <rect x={284} y={263} width={122} height={122} fill="#FFBA08" />
                <text x={530} y={335} fill="#5e5e5e" className="fnt0">
                  Microsoft ile oturum açın
                </text>
              </svg>
            </a>
          </div>
          <div style={{ fontSize: 14 }} className="gx-text-light gx-fs-sm gx-mt-2 gx-text-center ">
            <Link to="/parola-sifirlama">
              {' '}
              <FormattedMessage id="app.userAuth.forgotPassword" />
            </Link>
          </div>
        </Form>
      </div>
    </Login>
  );
};

export default injectIntl(SignIn);
