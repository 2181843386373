import { getCookies } from 'fetch/tokenHandler';
import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
} from '../../constants/ActionTypes';

const { token, refreshToken } = getCookies();

const INIT_STATE = {
  token: token ?? null,
  refreshToken: refreshToken ?? null,
  initURL: '/e-kaynaklar',
  authUser: {},
  user: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        refreshToken: null,
        authUser: null,
        initURL: '/giris',
        user: null,
      };
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload.user,
        user: action.payload.user,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default:
      return state;
  }
};
