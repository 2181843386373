import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { InfoView } from 'components';

const NotFound = ({ text = '' }) => {
  const intl = useIntl();

  return (
    <Result
      status="404"
      title="404"
      subTitle={intl.formatMessage({ id: 'notFoundTitle' }) + text}
      extra={
        <Link to={`/`}>
          <Button type="primary">Ana Sayfa</Button>
          <InfoView />
        </Link>
      }
    />
  );
};

export default NotFound;
