import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDocumentTitle } from 'hooks';
const ErrorPage = () => {
  const intl = useIntl();
  useDocumentTitle(intl.formatMessage({ id: 'errorPageTitle' }));
  return (
    <Result
      status="500"
      title="Hata"
      subTitle={intl.formatMessage({ id: 'errorPageTitle' })}
      extra={
        <Link to={`/`}>
          <Button type="primary">Ana Sayfa</Button>
        </Link>
      }
    />
  );
};

export default ErrorPage;
