import { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useGetUserInfo } from 'useSWR';

import { Menu } from 'antd';
import menuItems from '../menuItems';

const HorizontalNav = () => {
  const { isAdmin, isE2User, isResourceAndGuideUser } = useGetUserInfo();

  const { pathname } = useSelector(({ common }) => common);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const menu = useMemo(() => {
    if (isAdmin) return menuItems;

    const filtered = menuItems.filter((item) => {
      const { situation } = item;

      if (situation === 'all') return true;
      if (situation === 'onlyE2') return isE2User;
      if (situation === 'onlyGuides') return isResourceAndGuideUser;

      return false;
    });

    return filtered;
  }, [isAdmin, isE2User, isResourceAndGuideUser]);

  return (
    <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} mode="horizontal" className="gx-text-white">
      {menu.map((item) => (
        <Menu.Item icon={item.icon} key={item.key}>
          <Link to={item.url} style={{ color: 'inherit' }}>
            <FormattedMessage id={item.textId} />
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;
