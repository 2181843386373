import React from 'react';

import { Popover, Menu } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

import { useGetUserInfo } from 'useSWR';
import { useWindowSize } from 'hooks';
import { logout } from 'fetch/fetchList';
import { useHistory } from 'react-router-dom';

import 'styles/customCSS/leftUserSettings.less';

const UserProfile = React.memo(({ pageType }) => {
  const history = useHistory();

  const { user } = useGetUserInfo();
  const { width } = useWindowSize();

  const signOut = async () => {
    await logout();
    history.push('/giris');
  };

  const userMenuOptions = (
    <Menu>
      <Menu.Item icon={<LogoutOutlined />} key="logout" onClick={signOut}>
        Çıkış Yap
      </Menu.Item>
    </Menu>
  );

  return (
    <Popover placement="bottom" content={userMenuOptions} trigger="click" className="gx-p-0">
      <span className="gx-avatar-name gx-text-center gx-text-white">
        <UserOutlined style={{ marginRight: 10 }} />

        <div className={`${width < 600 && pageType !== 'side' ? 'gx-d-none' : 'gx-d-inline-block gx-mr-2'}`}>{' ' + user?.fullName ?? ''}</div>
        <i className="icon icon-chevron-down gx-fs-xxs " />
      </span>
    </Popover>
  );
});

export default UserProfile;
