import React from 'react';
import { Card } from 'antd';

import { Link } from 'react-router-dom';

const TokenExpired = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card className="gx-p-2" style={{ width: '500px' }}>
        <div style={{ margin: '24px 48px 24px 48px' }} className="gx-p-2">
          <Link to={'/'}>
            {' '}
            <img
              src="https://cdn.islamansiklopedisi.org.tr/assets/w/images/layouts/dia-yatay-logo.svg"
              alt="İSAM"
              width={'100%'}
            />
          </Link>
        </div>
        <h4
          className="gx-mx-auto gx-w-75 gx-text-center gx-font-weight-semi-bold gx-p-2 gx-m-4 gx-text-grey"
          style={{ marginBottom: '48px !important' }}
        >
          TDV İSLAM ANSİKLOPEDİSİ <br /> 2. EDİSYON YÖNETİM SİSTEMİ
        </h4>

        <h4 className="gx-text-center">
          Tıklanılan linkin kullanım süresi dolmuştur. Lütfen işlemleri baştan
          tekrarlayınız.
        </h4>
        <div
          style={{ fontSize: 14 }}
          className="gx-text-light gx-fs-sm gx-mt-2 gx-text-center "
        >
          <Link to="/parola-sifirlama"> Parolamı Unuttum</Link>
        </div>
      </Card>
    </div>
  );
};

export default TokenExpired;
