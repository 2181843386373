import React from 'react';
import { passwordResetValidateToken } from 'fetch/fetchList';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../constants/ActionTypes';

const useResetPasswordValidateToken = ({ token = null, hasToken }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isValidate, setIsValidate] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkToken = useCallback(
    async (token) => {
      setLoading(true);
      dispatch({ type: FETCH_START });
      const data = { token };
      const res = await passwordResetValidateToken(data);

      if (res >= 400) {
        dispatch({
          type: FETCH_ERROR,
          payload: (
            <FormattedMessage id="app.userAuth.PassResetTokenTimeoutError" />
          ),
        });
        setLoading(false);
        setTimeout(() => {
          history.push('/');
        }, 4000);
      } else {
        dispatch({ type: FETCH_SUCCESS });
        setLoading(false);
        setIsValidate(true);
      }
    },
    [dispatch, history]
  );

  useEffect(() => {
    if (hasToken) {
      checkToken(token);
    }
  }, [token, checkToken, hasToken]);

  return { isValidate, loading };
};

export default useResetPasswordValidateToken;
