import useSWR from 'swr';

const useGetUserInfo = () => {
  const { data, error, mutate } = useSWR('/current_user', {
    revalidateOnFocus: false,
  });

  let isSuperAdmin,
    isAdmin,
    isResourceManager,
    isE2User,
    isResourceUser,
    isResourceAndGuideUser,
    memberships = [];

  if (data) {
    isSuperAdmin = data.roles.includes('ROLE_SUPER_ADMIN');
    isAdmin = isSuperAdmin || data.roles.includes('ROLE_ADMIN');
    isE2User = data.roles.includes('ROLE_USER');
    isResourceManager = data.roles.includes('ROLE_ERESOURCE');
    isResourceAndGuideUser = isE2User || data.roles.includes('ROLE_RESOURCE_AND_GUIDE_USER');
    isResourceUser = isE2User || isResourceAndGuideUser || data.roles.includes('ROLE_RESOURCE_USER');
    memberships = data.memberships;
  }

  return {
    user: data,
    isLoading: !data && !error,
    isError: error,
    mutate,
    isResourceManager,
    isAdmin,
    isSuperAdmin,
    isE2User,
    isResourceUser,
    isResourceAndGuideUser,
    memberships,
  };
};

export default useGetUserInfo;
