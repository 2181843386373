import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetUserInfo } from 'useSWR';
import { getCookies } from 'fetch/tokenHandler';
import { Loading } from 'components';

const AppRedirect = () => {
  const history = useHistory();
  const { isLoading, user, isResourceUser } = useGetUserInfo();

  useEffect(() => {
    const { token: tokenCookie, refreshToken: rtCookie } = getCookies();

    if (!isLoading) {
      if (user && tokenCookie && rtCookie && isResourceUser) {
        history.push('/e-kaynaklar');
      } else {
        history.push('/giris');
      }
    }
  }, [user, history, isLoading, isResourceUser]);

  return <Loading />;
};

export default AppRedirect;
