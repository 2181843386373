import React, { useCallback, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { passwordReset, passwordResetRequest } from 'fetch/fetchList';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from 'constants/ActionTypes';
import { useResetPasswordValidateToken } from 'hooks';
import { CircularProgress } from 'components';
import { regexPass } from 'utils/textUtil';
import NotFound from 'routes/404';

import Login from './Login';

const ResetPassword = ({ noToken, intl }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  let location = useLocation();
  let history = useHistory();

  const { pathname } = location;
  const token = pathname.replaceAll('/parola-sifirlama/', '');

  const [hasToken] = useState(!noToken);
  const { isValidate, loading } = useResetPasswordValidateToken({
    token,
    hasToken: !noToken,
  });

  const [toggleMessage, setToggleMessage] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disableAll, setDisableAll] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = useCallback(
    async (values) => {
      setButtonLoading(true);
      dispatch({ type: FETCH_START });

      if (hasToken) {
        const data = { token, password: values.password };
        try {
          const res = await passwordReset(data);
          if (res) {
            setDisableAll(true);
            setToggleMessage(true);
            setButtonLoading(false);
            dispatch({
              type: FETCH_SUCCESS,
              payload: intl.formatMessage({
                id: 'app.userAuth.PassResetComplettedInfo',
              }),
            });
            setTimeout(() => {
              history.push('/');
            }, 3000);
          }
        } catch (error) {
          dispatch({
            type: FETCH_ERROR,
            payload: intl.formatMessage({
              id: 'app.error',
            }),
          });
        }
      } else {
        const res = await passwordResetRequest(values);

        if (res !== 429) {
          setToggleMessage(true);
          setButtonLoading(false);
          dispatch({
            type: FETCH_SUCCESS,
            payload: intl.formatMessage({
              id: 'app.userAuth.PassResetEmailSuccess',
            }),
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: intl.formatMessage({
              id: 'app.userAuth.PassResetEmailSoMuchRequest',
            }),
          });
          setButtonLoading(false);
          setDisableAll(true);
        }
      }
    },
    [dispatch, hasToken, history, token, intl]
  );

  if (loading) return <CircularProgress />;

  if (hasToken && !isValidate)
    return (
      <NotFound
        text={intl.formatMessage({
          id: 'app.userAuth.PassResetTokenTimeout',
        })}
      />
    );

  return (
    <Login>
      <h4 className="gx-mx-auto gx-w-75 gx-text-center gx-font-weight-semi-bold  gx-text-grey">
        <FormattedMessage id="app.userAuth.HeaderResetPass" />
      </h4>
      {!hasToken && (
        <div className="gx-text-light gx-fs-sm gx-mt-2 gx-text-center">
          <FormattedMessage id="app.userAuth.HeaderResetPassInfo" />
        </div>
      )}
      <div className="gx-m-4 gx-p-2">
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
          size="large"
          form={form}
        >
          {hasToken ? (
            <>
              {' '}
              <Form.Item
                className="gx-mb-3"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'app.userAuth.PassError',
                    }),
                  },
                  {
                    pattern: regexPass,
                    message: intl.formatMessage({
                      id: 'app.userAuth.PassResetInfoMessageType',
                    }),
                  },
                  {
                    min: 8,
                    message: intl.formatMessage({
                      id: 'app.userAuth.PassResetInfoMessageCharacter',
                    }),
                  },
                ]}
                name="password"
                hasFeedback
              >
                <Input.Password
                  type="password"
                  placeholder={intl.formatMessage({
                    id: 'userEdit.userPassNew',
                  })}
                  disabled={disableAll || !isValidate}
                />
              </Form.Item>{' '}
              <Form.Item
                className="gx-mb-3"
                name="passwordCheck"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'app.userAuth.PassAgainError',
                    }),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          intl.formatMessage({
                            id: 'app.userAuth.PassNotMatch',
                          })
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={intl.formatMessage({
                    id: 'userEdit.userPassAgain',
                  })}
                  disabled={disableAll || !isValidate}
                />
              </Form.Item>{' '}
            </>
          ) : (
            <Form.Item
              className="gx-mb-3"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'app.userAuth.EmailErrorCheck',
                  }),
                  type: 'email',
                },
              ]}
              name="email"
            >
              <Input placeholder={intl.formatMessage({ id: 'app.email' })} />
            </Form.Item>
          )}

          <Form.Item className="gx-text-right">
            <Button
              type="primary"
              className="gx-mb-0  gx-w-100"
              htmlType="submit"
              loading={buttonLoading}
              disabled={toggleMessage || disableAll || (!isValidate && hasToken)}
            >
              <FormattedMessage id="form.send" />
            </Button>
          </Form.Item>
          {!hasToken && (
            <div className="gx-text-light gx-fs-sm gx-mt-2 gx-text-center">
              <FormattedMessage id="app.userAuth.BottomResetPassInfo" />
            </div>
          )}

          {toggleMessage && !hasToken && (
            <>
              <div
                style={{ fontSize: 14 }}
                className="gx-fs-sm gx-mx-auto gx-my-0 gx-text-center gx-mt-2 gx-d-flex gx-justify-content-center gx-link"
                onClick={() => setToggleMessage(!toggleMessage)}
              >
                <FormattedMessage id="app.userAuth.PassResetEmailAgain" />
              </div>
            </>
          )}
        </Form>
      </div>
    </Login>
  );
};

export default injectIntl(ResetPassword);
