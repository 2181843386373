import { memo, useEffect } from 'react';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from 'appRedux/actions/Common';
import { CircularProgress } from 'components';

export const InfoView = memo(() => {
  const dispatch = useDispatch();

  const error = useSelector(({ common }) => common.error);

  const loading = useSelector(({ common }) => common.loading);

  const displayMessage = useSelector(({ common }) => common.message);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideMessage());
    }, 3000);
  }, [error, dispatch, displayMessage]);

  return (
    <>
      {loading && (
        <div className="gx-loader-view gx-loader-position">
          <CircularProgress />
        </div>
      )}
      {error && message.error(<span id="message-id">{error}</span>)}
      {displayMessage && message.info(<span id="message-id">{displayMessage}</span>)}
    </>
  );
});
