import axios from 'axios';
import { API_URL } from './Config';
import { getCookies, removeCookies, setCookies } from './tokenHandler';

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const { token } = getCookies();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const { refreshToken } = getCookies();
          const rs = await axios.post(`${API_URL}/refresh_token`, {
            refreshToken,
          });
          const { token, refreshToken: newRs } = rs.data;

          setCookies(token, newRs);
          instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          return instance(originalConfig);
        } catch (_error) {
          if (
            err.response.status === 401 &&
            originalConfig.url.includes('/current_user') &&
            originalConfig._retry
          ) {
            removeCookies();
            instance.defaults.headers.common['Authorization'] = '';
          }

          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
