import loadable from '@loadable/component';

export const allRoutes = [
  {
    key: 'e-kaynaklar',
    path: '/e-kaynaklar',
    component: loadable(() => import('./resources')),
    situation: 'all',
  },
  {
    key: 'hakkinda',
    path: '/hakkinda',
    component: loadable(() => import('./about')),
    situation: 'all',
  },
  {
    key: 'kilavuzlar-yonergeler',
    path: '/kilavuzlar-yonergeler',
    component: loadable(() => import('./instruction')),
    situation: 'onlyGuides',
  },
  {
    key: 'iys-kullanim-kilavuzu',
    path: '/iys-kullanim-kilavuzu',
    component: loadable(() => import('./user-manual')),
    situation: 'onlyE2',
  },
];
