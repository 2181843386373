import React, { useEffect } from 'react';
import { ConfigProvider, Result, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SWRConfig } from 'swr';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';

import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import trTR from 'antd/lib/locale/tr_TR';
import { IntlProvider } from 'react-intl';
import AppLocale from 'lngProvider';

import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions/Setting';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from 'constants/ThemeSetting';

import axios from 'fetch/customAxios';

import ResetPassword from 'containers/ResetPassword';
import TokenExpired from 'containers/TokenExpiredForPass';
import SignIn from 'containers/SignIn';

import MainApp from './MainApp';
import AppRedirect from './AppRedirect';

ReactGA.initialize(process.env.REACT_APP_GA_ID);
Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { locale, navStyle, layoutType } = useSelector(({ settings }) => settings);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [dispatch, layoutType, location.pathname, location.search, navStyle]);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <Sentry.ErrorBoundary
      fallback={
        <Result
          status="warning"
          title="Bir problemle karşılaşıldı."
          extra={
            <Button onClick={() => window.location.reload(true)} type="primary" key="console">
              Sayfayı Yenile
            </Button>
          }
        />
      }
    >
      <SWRConfig
        value={{
          fetcher: async (url) => {
            const res = await axios.get(url);
            return res.data;
          },
        }}
      >
        <ConfigProvider locale={trTR}>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages} defaultLocale="tr">
            <Switch>
              <Route exact path="/" component={AppRedirect} />
              <Route exact path="/giris" component={SignIn} />
              <Route exact path="/parola-sifirlama-hata" component={TokenExpired} />
              <Route path="/parola-sifirlama/:token" strict component={ResetPassword} />
              <Route exact path={['/parola-sifirlama', '/parola-sifirlama/']}>
                <ResetPassword noToken={true} />
              </Route>
              <Route path="*" location={location} component={MainApp} />
            </Switch>
          </IntlProvider>
        </ConfigProvider>
      </SWRConfig>
    </Sentry.ErrorBoundary>
  );
};

export default withRouter(App);
