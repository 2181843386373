import React from 'react';
import Pulse from 'react-reveal/Pulse';
import { Row } from 'antd';
import { ISAMDiaLogo } from 'containers/Logos';

export const Loading = () => {
  return (
    <Pulse>
      <Row justify="center" align="center">
        <div
          style={{
            width: '100vw',
            height: '90vh',
          }}
          className="gx-d-flex gx-align-items-center gx-justify-content-center "
        >
   <ISAMDiaLogo width={300} />
        </div>
      </Row>
    </Pulse>
  );
};
