import { useMediaQuery as useMediaQueryRR } from 'react-responsive';

const useMediaQuery = () => {
  const isMobile = useMediaQueryRR({ query: '(max-width: 767px)' });
  const isTablet = useMediaQueryRR({ query: '(max-width: 1279px)' });
  const isDesktop = useMediaQueryRR({ query: '(min-width: 1280px)' });

  const isSmScreen = useMediaQueryRR({ query: '(max-width: 639px)' });
  const isMdScreen = useMediaQueryRR({ query: '(max-width: 767px)' });
  const isLgScreen = useMediaQueryRR({ query: '(max-width: 1023px)' });
  const isXlScreen = useMediaQueryRR({ query: '(max-width: 1279px)' });

  return {
    isMobile,
    isTablet,
    isDesktop,
    isSmScreen,
    isMdScreen,
    isLgScreen,
    isXlScreen,
  };
};

export default useMediaQuery;
